import React, { useState } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from 'react-toastify';
import Select, { createFilter, components } from 'react-select';

import DateTimePicker from "../../shipment_request/DateTimePicker";
import InputFieldComponent from "../../common_components/InputFieldComponent";
import NestedCustomSelect from "../../NestedCustomSelect";
import PhoneNumber from "../phoneNumber/PhoneNumber";
const Bin = require("../../../../assets/images/bin.svg");
const Calender = require("../../../../assets/images/calendar.svg");

import 'react-toastify/dist/ReactToastify.css';

interface TravellerSearchProps {
  index: number;
  attr: string;
  newAttr: string;
  genders: Array<string>;
  placeholder: string;
  availableTravellers: object;
  selectedTravellers: object;
  transportRequestTravellerIds: any;
  transplantCenterId: number;
}

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    fontSize: "0.875rem"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#eef5f8" : "white",
    color: "#010928"
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: "#F6FAFB",
    fontSize: "0.875rem",
    padding: "0rem",
    height: "40px",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#010928"
  })
}

const TravellerSearch = ({
  attr,
  newAttr,
  placeholder,
  genders,
  index,
  selectedTravellers,
  availableTravellers,
  transplantCenterId
}: TravellerSearchProps) => {
  const [idindex, setIdIndex] = useState(0)
  const [travellers, setTravellers] = useState(selectedTravellers);
  const [currentIndex, setCurrentIndex] = useState(selectedTravellers.length);
  const travellerState = { id: idindex, first_name: "", last_name: "", contact_email_id: "", contact_number: "", date_of_birth: "", weight: "", dietary_restrictions: "", gender: "", _destroy: false };
  const [traveller, setTraveller] = useState(travellerState)
  const [newTravellers, setNewTravellers] = useState([])

  const handleAddInputs = () => {
    if(newTravellers.length < 5) {
      setNewTravellers([...newTravellers, { traveller: traveller }])
      setCurrentIndex(currentIndex)
      setIdIndex(idindex + 1)
      setTraveller({ ...traveller, ...{ id: idindex + 1 } })
    } else {
      toast.error("You can only add details for 5 travelers");
    }
  };

  const handleRemoveInputs = (index) => {
    let tempTravellers = travellers
    if(tempTravellers[index]["traveller_id"]) {
      tempTravellers.splice(index, 1)
    } else {
      tempTravellers[index]["_destroy"] = true;
    }
    setTravellers([...tempTravellers]);
  };

  const handleNewRemoveInputs = (index) => {
    let tempTravellers = newTravellers
    tempTravellers.splice(index, 1)
    setNewTravellers([...tempTravellers]);
  };

  const handleStateUpdate = (e, id, key) => {
    let tempTravellers = travellers
    tempTravellers[id].traveller[key] = e;
    setTravellers([...tempTravellers]);
  }

  const handleNewStateUpdate = (e, id, key) => {
    let tempTravellers = newTravellers
    tempTravellers[id][key] = e;
    setNewTravellers([...tempTravellers]);
  }

  const handleValueChange = (item) => {
    const traveller_details = availableTravellers.find((traveller) => traveller.id == item.value);
    const traveller_hash = {}
    traveller_hash["traveller_id"] = traveller_details.id
    traveller_hash["traveller"] = traveller_details
    delete traveller_details.id
    setTravellers([...travellers, traveller_hash])
    setCurrentIndex(currentIndex)
    setIdIndex(idindex + 1)
  };


  let selectOptions = [];
  availableTravellers.map(option => {
    const obj = { label: `${option.first_name}  ${option.last_name}`, value: option.id }
    selectOptions = [...selectOptions, obj];
  });

  const DropdownOption = ({ innerRef, innerProps, children }) => {
    return (
      <>
        <div ref={innerRef} {...innerProps} className="menu-item-traveller font-size-sm">
          {children}
          <div
            className="btn letter-spacing add-traveller-btn text-charter-oxford-blue btn-sm btn-block"
            onClick={handleAddInputs}
          >
            + ADD NEW TRAVELER DETAILS
          </div>
        </div>
      </>
    );
  };

  const isTravelerPresent = (travellers && travellers.length) || newTravellers.length;
  return (
    <>
      <div id="traveller-details-errors" className="text-danger font-size-xs"></div>
      <ToastContainer
        position="bottom-left"
        autoClose={6000}
        hideProgressBar={true}
        className="toast-position"
        bodyClassName="toastBody"
      />
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        options={selectOptions}
        components={{ Menu: DropdownOption }}
        isSearchable
        name={name}
        styles={customStyles}
        placeholder={placeholder}
        onChange={handleValueChange}
      />

      <div className="traveller-wrapper py-2">
        <div className={`traveller-details ${!isTravelerPresent && "d-flex justify-content-center align-items-center"}`}>
          { isTravelerPresent ?
          (
            <>
            {travellers.map((traveller, id) => {
            return (
              <div className={`row ${traveller._destroy === true ? "hidden" : ""}`} key={id}>
                <div className="col-12 p-0">
                  <div className="row p-o m-0">
                    { traveller.id ? (<input type="hidden" name={`${attr}[${id}][id]`} value={traveller.id} />) : null }
                    { traveller.traveller_id ? (
                      <>
                        <input type="hidden" name={`${attr}[${id}][traveller_id]`} value={traveller.traveller_id} />
                        <input type="hidden" name={`${attr}[${id}][traveller_attributes][id]`} value={traveller.traveller_id} />
                      </>
                    ) : null }
                    { traveller.traveller.id ?
                      (<input type="hidden" name={`${attr}[${id}][traveller_attributes][id]`} value={traveller.traveller.id} />)
                      : null }
                    <input type="hidden" name={`${attr}[${id}][_destroy]`} value={traveller._destroy} />
                    <div className="col-4">
                      <InputFieldComponent
                        label={"First Name"}
                        attr="first_name"
                        id={id}
                        value={traveller.traveller.first_name}
                        name={`${attr}[${id}][traveller_attributes][first_name]`}
                        handleChange={handleStateUpdate}
                      />
                    </div>
                    <div className="col-4">
                      <InputFieldComponent
                        label={"Last Name"}
                        attr="last_name"
                        id={id}
                        value={traveller.traveller.last_name}
                        name={`${attr}[${id}][traveller_attributes][last_name]`}
                        handleChange={handleStateUpdate}
                      />
                    </div>
                    <div className="col-4">
                      <InputFieldComponent
                        label={"Contact email ID"}
                        attr="contact_email_id"
                        id={id}
                        // isRequired
                        value={traveller.traveller.contact_email_id}
                        name={`${attr}[${id}][traveller_attributes][contact_email_id]`}
                        handleChange={handleStateUpdate}
                      />
                    </div>
                  </div>
                  <div className="row p-o m-0">
                    <div className="col-4">
                      <div className="form-group mb-3">
                        <span className="font-size-xs text-charter-oxford-blue-60">Contact number</span>
                        <PhoneNumber
                          name={`${attr}[contact_number]`}
                          formValue={traveller.traveller.contact_number}
                          placeholder={"Contact number"}
                          id={id}
                          attr={"contact_number"}
                          handleChange={handleStateUpdate}
                          fromProfile
                          className={"form-control"}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group mb-3 datetime">
                        <span className="font-size-xs text-charter-oxford-blue-60">Date of birth</span>
                        <span className="traveller-calendar">
                          <img src={Calender} />
                        </span>
                        <div className="form-control">
                          <DateTimePicker
                            name={`${attr}[${id}][traveller_attributes][date_of_birth_datetime]`}
                            placeholder={"Date of birth"}
                            dataProps={ { required: "true" } }
                            isRequired={"true"}
                            isShipment={true}
                            edit
                            dateFormat="MM/DD/YYYY"
                            timeFormat={false}
                            value={traveller.traveller.date_of_birth}
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <InputFieldComponent
                        label={"Weight"}
                        attr="weight"
                        id={id}
                        value={traveller.traveller.weight}
                        isRequired
                        name={`${attr}[${id}][traveller_attributes][weight]`}
                        handleChange={handleStateUpdate}
                      />
                    </div>
                  </div>
                  <div className="row p-o m-0">
                    <div className="col-8">
                      <InputFieldComponent
                        label={"Dietary Restrictions (Optional)"}
                        attr="dietary_restrictions"
                        id={id}
                        value={traveller.traveller.dietary_restrictions}
                        name={`${attr}[${id}][traveller_attributes][dietary_restrictions]`}
                        handleChange={handleStateUpdate}
                      />
                    </div>
                    <div className="col-4">
                      <span className="font-size-xs text-charter-oxford-blue-60">Gender</span>
                      <NestedCustomSelect
                        name={`${attr}[${id}][traveller_attributes][gender]`}
                        options={genders}
                        placeholder="Select"
                        isMulti={false}
                        isWhiteBg={true}
                        value={traveller.traveller.gender}
                        index={id}
                        handleValueChange={handleStateUpdate}
                      />
                    </div>
                    <div className="col-1 d-flex align-items-center">
                      <img onClick={(e) => handleRemoveInputs(id)} id={"bin-aircraft" + id} src={Bin} />
                    </div>
                  </div>
                  <div className="divider py-2"></div>
                </div>
              </div>)
          }
          )}

          {newTravellers.map((traveller, id) => {
            return (
              <div className="row" key={id}>
                <div className="col-12 p-0">
                  <div className="row p-o m-0">
                    <input type="hidden" name={`${newAttr}[${id}][transplant_center_id]`} value={transplantCenterId} />
                    <div className="col-4">
                      <InputFieldComponent
                        label={"First Name"}
                        attr="first_name"
                        id={id}
                        value={traveller.first_name}
                        name={`${newAttr}[${id}][first_name]`}
                        handleChange={handleNewStateUpdate}
                      />
                    </div>
                    <div className="col-4">
                      <InputFieldComponent
                        label={"Last Name"}
                        attr="last_name"
                        id={id}
                        value={traveller.last_name}
                        name={`${newAttr}[${id}][last_name]`}
                        handleChange={handleNewStateUpdate}
                      />
                    </div>
                    <div className="col-4">
                      <InputFieldComponent
                        label={"Contact email ID"}
                        attr="contact_email_id"
                        id={id}
                        // isRequired
                        value={traveller.contact_email_id}
                        name={`${newAttr}[${id}][contact_email_id]`}
                        handleChange={handleNewStateUpdate}
                      />
                    </div>
                  </div>
                  <div className="row p-o m-0">
                    <div className="col-4">
                      <div className="form-group mb-3">
                        <span className="font-size-xs text-charter-oxford-blue-60">Contact number</span>
                        <PhoneNumber
                          name={`${newAttr}[${id}][contact_number]`}
                          formValue={traveller.contact_number}
                          placeholder={"Contact number"}
                          id={id}
                          attr={"contact_number"}
                          handleChange={handleNewStateUpdate}
                          fromProfile
                          className={"form-control"}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group mb-3 datetime">
                        <span className="font-size-xs text-charter-oxford-blue-60">Date of birth</span>
                        <span className="traveller-calendar">
                          <img src={Calender} />
                        </span>
                        <div className="form-control">
                          <DateTimePicker
                            name={`${newAttr}[${id}][date_of_birth_datetime]`}
                            placeholder={"Date of birth"}
                            isShipment={true}
                            dataProps={ { required: "true" } }
                            isRequired={"true"}
                            edit
                            dateFormat="MM/DD/YYYY"
                            timeFormat={false}
                            value={traveller.traveller.date_of_birth}
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <InputFieldComponent
                        label={"Weight"}
                        attr="weight"
                        id={id}
                        value={traveller.weight}
                        isRequired
                        name={`${newAttr}[${id}][weight]`}
                        handleChange={handleNewStateUpdate}
                      />
                    </div>
                  </div>
                  <div className="row p-o m-0">
                    <div className="col-8">
                      <InputFieldComponent
                        label={"Dietary Restrictions (Optional)"}
                        attr="dietary_restrictions"
                        id={id}
                        value={traveller.dietary_restrictions}
                        name={`${newAttr}[${id}][dietary_restrictions]`}
                        handleChange={handleNewStateUpdate}
                      />
                    </div>
                    <div className="col-4">
                      <span className="font-size-xs text-charter-oxford-blue-60">Gender</span>
                      <NestedCustomSelect
                        name={`${newAttr}[${id}][gender]`}
                        options={genders}
                        placeholder="Select"
                        isMulti={false}
                        isWhiteBg={true}
                        value={traveller.gender}
                        index={id}
                        handleValueChange={handleNewStateUpdate}
                      />
                    </div>
                    <div className="col-1 d-flex align-items-center">
                      <img onClick={(e) => handleNewRemoveInputs(id)} id={"bin-aircraft" + id} src={Bin} />
                    </div>
                  </div>
                  <div className="divider py-2"></div>
                </div>
              </div>)
          }
          )}
          </>
          ) : (
            <div className="text-charter-oxford-blue-40">
              You haven’t added any traveler details for this transport request
            </div>
          )
        }
        </div>
      </div>
    </>
  );
}

TravellerSearch.propTypes = {
  attr: PropTypes.string.isRequired,
  newAttr: PropTypes.string.isRequired,
  availableTravellers: PropTypes.object.isRequired,
  selectedTravellers: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  transportRequestTravellerIds: PropTypes.array,
  transplantCenterId: PropTypes.object.isRequired,
}

TravellerSearch.defaultProps = {
  placeholder: "Enter traveler’s name",
  transportRequestTravellerIds: [],
}

export default TravellerSearch;
