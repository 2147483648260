import consumer from "./consumer";

document.addEventListener("turbolinks:load", () => {
  var userId = document.getElementById("userId");

  if (userId === undefined || userId === null) {
    return false;
  }

  if (window.channel) {
    return false;
  }

  window.channel = consumer.subscriptions.create(
    {
      channel: "CsvExportChannel",
      id: userId.dataset["userId"],
    },
    {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        const exportProgressDetail = document.querySelector(
          "#export-progress-detail"
        );
        const exportCompleted = document.querySelector("#export-completed");
        const exportCloseBtn = document.querySelector("#export-close-btn");

        if (data["csv_file"]) {
          const blob = new Blob([data["csv_file"]["content"]]);

          let csv_download_link = document.createElement("a");
          csv_download_link.href = window.URL.createObjectURL(blob);
          csv_download_link.download = data["csv_file"]["file_name"];
          csv_download_link.click();

          if (exportProgressDetail)
            exportProgressDetail.classList.add("export_hide");
          if (exportCompleted) exportCompleted.classList.remove("export_hide");
          if (exportCloseBtn) exportCloseBtn.classList.remove("disabled");
        } else if (data["progress"]) {
          let exportProgressPercentages = document.querySelectorAll(
            ".export-progress-percentage"
          );
          let exportProgressBar = document.querySelector(
            ".export-progress-bar"
          );

          exportProgressPercentages.forEach(percentage => {
            percentage.innerHTML = data["progress"];
          });

          if (exportProgressBar) {
            exportProgressBar.style.width = `${data["progress"]}%`;
          }
        } else if (data["completed"]) {
          const importCount = document.querySelector("#import-count");
          const importClose = document.querySelector("#import-close");
          const travellersId = document.querySelector("#travellers-id");
          importCount.innerHTML = data["completed"];

          if (exportProgressDetail)
            exportProgressDetail.classList.add("export_hide");
          if (exportCompleted) exportCompleted.classList.remove("hidden");
          if (importClose) importClose.click();
          if (travellersId) {
            const travellerLink = travellersId.querySelector("a");
            travellerLink.click();
          }
        }
      },
    }
  );
});
