import React, { useState } from "react"
import PropTypes from "prop-types"

interface InputFieldComponentProps {
  name: string;
  attr: string;
  label: string;
  id: any;
  value: string;
  handleChange: any;
  inputType: string;
  isRequired: boolean;
}

const InputFieldComponent = ({ label, attr, id, value, handleChange, name, inputType, isRequired }: InputFieldComponentProps) => {
  return (
    <div className="form-group mb-3">
      <span className="font-size-xs text-charter-oxford-blue-60">{label}</span>
      <div>
        <input
          type={inputType}
          name={name}
          value={value}
          placeholder={label}
          required={isRequired}
          onChange={(e) => handleChange(e.target.value, id, attr)}
          className="form-control" />
      </div>
    </div>
  );
}

InputFieldComponent.propTypes = {
  name: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.any,
  inputType: PropTypes.string,
}

InputFieldComponent.defaultProps = {
  inputType: "text",
  isRequired: false,
}

export default InputFieldComponent;
