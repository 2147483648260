import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shipment--roundtrip-oneway"
export default class extends Controller {
  static targets = ["inputField"];

  connect() {}

  roundTrip() {
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.selectedIndex = 4;
    }
  }

  onewayTrip() {
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.selectedIndex = 0;
    }
  }
}
