import React, { useState } from "react"
import PropTypes from "prop-types"
import { components, createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import { customStyles, CheckboxValueContainer, DropdownOption, CustomOption } from '../utils';

interface MultiAirportDropdownProps {
  name: string;
  options: string[];
  placeholder: string;
  values: string[];
  isMulti: boolean;
}

const MultiAirportDropdown = ({ name, options, placeholder, values, isMulti }:MultiAirportDropdownProps) => {
  const [inputValue, setValue] = useState('');
  let selectOptions = [];

  options.map(option => selectOptions.push({label: option.label, value: option.value }));

  const handleInputChange = value => {
    setValue(value);
  };

  const loadOptions = (inputValue) => {
    const value = new URLSearchParams({
      keyword: inputValue
    })

    return fetch(`/airports?${value}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
  }

  let defaultValues = [];
  let components = { Option: DropdownOption }
  let closeMenuOnSelect = false;
  let isClearable = false;

  if (values) {
    defaultValues = options.filter((option) => values.includes(option.value))
  }

  if (isMulti) {
    components["ValueContainer"] = CheckboxValueContainer;
  } else {
    components["Option"] = CustomOption;
    closeMenuOnSelect = true;
    isClearable = true;
  }

  return (
    <>
      <AsyncSelect
        cacheOptions
        defaultOptions={selectOptions}
        filterOption={createFilter({ ignoreAccents: false })}
        components={components}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        defaultValue={defaultValues}
        isMulti={isMulti}
        styles={customStyles}
        hideSelectedOptions={false}
        placeholder={placeholder}
        blurInputOnSelect={false}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={closeMenuOnSelect}
        name={name}
      />
    </>
  );
}

MultiAirportDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool
}

MultiAirportDropdown.defaultProps = {
  isMulti: true,
  value: "",
  placeholder: "Type to search airport"
}

export default MultiAirportDropdown;
