// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    this.config = {
      allowInput: true,
      onOpen: function(selectedDates, dateStr, instance) {
        $(instance.altInput).prop("readonly", true);
      },
      onClose: function(selectedDates, dateStr, instance) {
        $(instance.altInput).prop("readonly", false);
        $(instance.altInput).blur();
      },
    };
  }
}
