import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomSelect from "../CustomSelect";

interface FormFieldProps {
  organizationOptions: string[];
  existingOrgOptions: string[];
  roleTypes: string[];
  organizationRoles: string[];
  orgVal: string;
  userVal: string;
  existingOrgVal: string;
  existingOrgName: string;
  orgRoleVal: string;
  nameVal: string;
  emailVal: string;
  existingUser: boolean;
}

const FormField = ({
  organizationOptions,
  existingOrgOptions,
  roleTypes,
  organizationRoles,
  orgVal,
  userVal,
  existingOrgVal,
  existingOrgName,
  orgRoleVal,
  nameVal,
  emailVal,
  existingUser
}: FormFieldProps) => {
  const [orgOptType, setOrgOptType] = useState(orgVal);
  const [userType, setUserType] = useState(userVal);
  const [existingOrg, setExistingOrg] = useState(existingOrgVal);
  const [orgRole, setOrgRole] = useState(orgRoleVal);
  const existingRecord = (orgVal === "existing_organization");

  const handleOrgOptionChanges = (e) => setOrgOptType(e.value);
  const handleExistingOrgChanges = (e) => setExistingOrg(e.value);
  const handleUserTypeChanges = (e) => setUserType(e.target.value);
  const handleOrgRoleChanges = (e) => setOrgRole(e.value);

  return (
    <>
      {existingRecord && (
        <>
          <input type="hidden" name="user[team_member]" value="true" />
          <input type="hidden" name="user[organization_option]" value="existing_organization" />
          <input type="hidden" name="user[user_type]" value={userVal} />
        </>
      )}

      {!existingRecord && (
        <div className="form-group">
          <label className="font-weight-normal">
            Organization Option
          </label>
          <div className="d-flex align-items-center">
            <CustomSelect
              name="user[organization_option]"
              options={organizationOptions}
              value={orgOptType}
              placeholder="Select Organization Option"
              isMulti={false}
              isWhiteBg={false}
              handleValueChange={handleOrgOptionChanges} />
          </div>
        </div>
      )}

      {orgOptType === "new_organization" ? (
        <div className="form-group">
          <label className="font-weight-normal">
            Organization Name
          </label>

          <input
            name="user[org_name]"
            className="form-control"
            placeholder="Enter Organization Name"
            type="text"
          />
        </div>
      ) : (
        <div className="form-group">
          <label className="font-weight-normal">
            Organization List
          </label>

          <div className="d-flex align-items-center">
            {(existingRecord && existingOrg) ? ( 
              <>
                <input type="hidden" name="user[org_id]" value={existingOrg} />

                <div className="form-control">
                  {existingOrgName}
                </div>
              </>
             ) : (
              <CustomSelect
                name="user[org_id]"
                options={existingOrgOptions}
                value={existingOrg}
                placeholder="Select Organization"
                isMulti={false}
                isWhiteBg={false}
                handleValueChange={handleExistingOrgChanges} />
            )}
          </div>
        </div>
      )}

      <div className="form-group">
        <label className="font-weight-normal">
          Contact Name
        </label>

        <input
          name="user[contact_name]"
          className="form-control"
          placeholder="Enter Contact Name"
          defaultValue={nameVal}
          type="text"
          required="required"
        />
      </div>

      <div className="form-group">
        <label className="font-weight-normal">
          Contact Email ID
        </label>

        <input
          name="user[email]"
          className="form-control"
          placeholder="Enter Contact Email ID"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          defaultValue={emailVal}
          disabled={(existingRecord && existingUser) ? "disabled" : ""}
          type="email"
          required="required"
        />
      </div>

      {orgOptType === "new_organization" ? (
        <>
          <label className="font-weight-normal">
            Type
          </label>
          { roleTypes.map((roleType) => (
            <div className="form-group custom-control custom-radio  mb-3">
              <input type="hidden" name="user[user_type]" value={userType} autocomplete="off" />

              <div className="mt-2">
                <input
                  className="custom-control-input "
                  type="radio"
                  value={roleType[1]}
                  checked={ roleType[1] === userType ? "checked" : "" }
                  name="user[user_type]"
                  onChange={handleUserTypeChanges}
                  id={`user_user_type_${roleType[1]}`} />
                <label className="custom-control-label" for={`user_user_type_${roleType[1]}`}>{roleType[0]}</label>
              </div>
            </div>
          ))}
        </>
      ) : (
      <div className="form-group">
        <label className="font-weight-normal">
          Organization Role
        </label>
        <div className="d-flex align-items-center">
          <CustomSelect
            name="user[organization_role]"
            options={organizationRoles}
            value={orgRole}
            placeholder="Select Organization Type"
            isMulti={false}
            isWhiteBg={false}
            handleValueChange={handleOrgRoleChanges} />
        </div>
      </div>
    )}
      
    </>
  );
}

FormField.propTypes = {
  organizationOptions: PropTypes.array.isRequired,
  existingOrgOptions: PropTypes.array.isRequired,
  roleTypes: PropTypes.array.isRequired,
  organizationRoles: PropTypes.array.isRequired,
  orgVal: PropTypes.string,
  userVal: PropTypes.string,
  existingOrgVal: PropTypes.string,
  existingOrgName: PropTypes.string,
  orgRoleVal: PropTypes.string,
  nameVal: PropTypes.string,
  emailVal: PropTypes.string,
  existingUser: PropTypes.bool,
}

FormField.defaultProps = {
  orgVal: "new_organization",
  userVal: "charter_operator",
  existingOrgVal: null,
  existingOrgName: null,
  orgRoleVal: null,
  nameVal: null,
  emailVal: null,
  existingUser: false
}

export default FormField;
