import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import DesktopInput from './DesktopInput';

interface ExistingInputProps {
  obj: object;
  attr: string;
  genders: Array<string>;
  index: any;
}

const ExistingInput = ({ obj, attr, genders, index }: ExistingInputProps) => {
  const travellerState = {
    id: index,
    first_name: obj.first_name,
    last_name: obj.last_name,
    contact_email_id: obj.contact_email_id,
    contact_number: obj.contact_number,
    date_of_birth: obj.date_of_birth,
    weight: obj.weight,
    dietary_restrictions: obj.dietary_restrictions,
    gender: obj.gender,
  };
  const [traveller, setTraveller] = useState(travellerState);
  const [deleteRecord, setDeleteRecord] = useState(false);

  const handleRemoveInputs = () => {
    setDeleteRecord(true);
  }

  const handleStateUpdate = (e, id, key) => {
    setTraveller(prev => ({...prev, [key]: e}))
  }

  return (
    <>
      <div className={`d-none d-lg-block ${deleteRecord === true ? "hidden" : ""}`} key={index}>
        <DesktopInput
          attr={attr}
          id={index}
          traveller={traveller}
          genders={genders}
          handleStateUpdate={handleStateUpdate}
          handleRemoveInputs={handleRemoveInputs}
        />
        <input type="hidden" name={`${attr}[id]`} value={obj.id} />
        <input type="hidden" name={`${attr}[_destroy]`} value={deleteRecord} />
        <div className="divider py-2"></div>
      </div>
    </>
  );
}

ExistingInput.propTypes = {
  obj: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  genders: PropTypes.array.isRequired,
}

export default ExistingInput;
