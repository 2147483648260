import React, { useState } from "react"
import { bytesToSize } from "./utils";
const Pdf = require("../../assets/images/svg/PDF.svg");

interface FileAttachmentProps {
  filename: string;
  byteSize: string;
  handleValueChange: any;
  is_import_file: boolean;
}

const FileAttachment = ({
  filename,
  byteSize,
  handleValueChange,
  is_import_file = false
}: FileAttachmentProps) => {
  return (
    <>
      <div className={`d-flex align-items-center justify-content-center row mx-1 py-1 ${ is_import_file ? "import-uploaded-file" : "uploaded-file" }`}>
        <div className="col-2">
          <img src={Pdf} />
        </div>
        <div className="col-8">
          <div>
            {filename}
          </div>
          <div className="text-charter-oxford-blue-40">
            {bytesToSize(byteSize)}
          </div>
        </div>
        <div className="col-2">
          <span className="text-charter-oxford-blue font-size-lg"
            onClick={handleValueChange}>
            ✕
          </span>
        </div>
      </div>
    </>
  );
}

export default FileAttachment;
