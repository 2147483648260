import React, { useState } from "react"
import PropTypes from "prop-types"

import NestedCustomSelect from "./NestedCustomSelect";
const Bin = require("../../assets/images/bin.svg");

interface AirplaneInputMobileProps {
  name: string;
  index: any;
  aircraftTypes: string[];
}

const AirplaneInputMobile = ({ name, index, aircraftTypes }: AirplaneInputMobileProps) => {
  const [airplanenum, setAirplanenum] = useState('')
  const [idindex, setIdIndex] = useState(0)
  const [airplaneCapacity, setAirplaneCapacit] = useState('')
  const [airplanetype, setAirplanetype] = useState('')
  const [airplaneModel, setAirplaneModel] = useState('')
  const [airplanes, setAirplanes] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(index + 1);
  const airplaneState = { id: idindex, airplane_number: "", seating_capacity: "", aircraft_type: "", aircraft_make_model: "" };
  const [airplane, setAirplane] = useState(airplaneState)

  const handleAddInputs = () => {
    setAirplanes([...airplanes, airplane])
    setCurrentIndex(currentIndex)
    setAirplanenum("")
    setAirplaneCapacit('')
    setAirplanetype("")
    setAirplaneModel('')
    setIdIndex(idindex + 1)
    setAirplane({ ...airplane, ...{ id: idindex + 1 } })
  };

  const handleRemoveInputs = (index) => {
    airplanes.splice(index, 1);
    const updatedList = airplanes
    setAirplanes([...updatedList]);
  };

  const attr = "charter_operator[aircrafts_attributes]";

  const handleAddAircraftNum = (e, id) => {
    setAirplanenum(e.target.value)
    let updateAirplane = airplanes
    updateAirplane[id].airplane_number = e.target.value;
    setAirplane(updateAirplane);
  }

  const handleAddAircraftCapacity = (e, id) => {
    setAirplaneCapacit(e.target.value)
    let updateAirplane = airplanes
    updateAirplane[id].seating_capacity = e.target.value;
    setAirplane(updateAirplane);
  }

  const handleAddAircrafttype = (e, id) => {
    setAirplanetype(e.value)
    let updateAirplane = airplanes
    updateAirplane[id].aircraft_type = e.value;
    setAirplane(updateAirplane);
  }

  const handleAddAircraftMakeModel = (e, id) => {
    setAirplanetype(e.value);
    let updateAirplane = airplanes;
    updateAirplane[id].aircraft_make_model = e.value;
    setAirplaneModel(updateAirplane);
  }


  return (
    <>
      <div className="d-lg-none">
        {airplanes.map((airplane, id) => {
          return (
            <div className="row" key={id}>
              <div className="col-12 p-0">
                <div className="row p-o m-0">
                  <div className="col-6 pl-3 pr-2">
                    <div className="form-group mb-3">
                      <span className="font-size-xs text-charter-oxford-blue-60">Tail Number</span>
                      <div><input type={"text"} name={`${attr}[${currentIndex + id}][aircraft_number]`} value={airplane.airplane_number} onChange={(e) => handleAddAircraftNum(e, id)} className="form-control" /></div>
                    </div>
                  </div>
                  <div className="col-6 pl-2 pr-3">
                    <div className="form-group mb-3">
                      <span className="font-size-xs text-charter-oxford-blue-60">Seating Capacity</span>
                      <div><input type={"number"} name={`${attr}[${currentIndex + id}][seating_capacity]`} value={airplane.seating_capacity} onChange={(e) => handleAddAircraftCapacity(e, id)} className="form-control" /></div>
                    </div>
                  </div>

                </div>
                <div className="row p-o m-0">
                  <div className="col-10">
                    <div className="form-group mb-3">
                      <span className="font-size-xs text-charter-oxford-blue-60">Aircraft Type</span>
                      <NestedCustomSelect
                        name={`${attr}[${currentIndex + id}][aircraft_type]`}
                        options={aircraftTypes}
                        placeholder="Select"
                        isMulti={false}
                        isWhiteBg={false}
                        value={airplane.aircraft_type}
                        index={id}
                        handleValueChange={handleAddAircrafttype}
                      />
                    </div>
                    <div className="col-10">
                      <div className="form-group mb-3">
                        <span className="font-size-xs text-charter-oxford-blue-60">Aircraft Make/Model</span>
                        <div>
                          <input type={"text"}
                            name={`${attr}[${currentIndex + id}][aircraft_make_model]`}
                            value={airplane.aircraft_make_model}
                            onChange={(e) => handleAddAircraftMakeModel(e, id)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 pr-3 d-flex flex-row align-items-center justify-content-end">
                    <img onClick={(e) => handleRemoveInputs(id)} id={"bin-aircraft" + id} src={Bin} />
                  </div>
                </div>
              </div>
            </div>)
        }
        )}
        <div className="row">
          <div className="col-12 p-3">
            <div
              className="w-100 text-uppercase add-aircraft-btn text-center px-4 py-1 font-size-normal letter-spacing text-charter-oxford-blue-20 add-certificate-btn"
              onClick={handleAddInputs}
            >
              ADD ANOTHER AIRCRAFT
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

AirplaneInputMobile.propTypes = {
  name: PropTypes.string.isRequired,
  aircraftTypes: PropTypes.string.isRequired,
}

export default AirplaneInputMobile;
