import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shipment--increment-decrement"
export default class extends Controller {
  static targets = [
    "passInputField",
    "confirmPassInputField",
    "eye",
    "confirmEye",
  ];

  connect() {}

  togglePassword() {
    if (this.hasPassInputFieldTarget) {
      this.eyeTarget.classList.toggle("fa-eye-slash");
      if (this.passInputFieldTarget.type == "password") {
        this.passInputFieldTarget.type = "text";
      } else {
        this.passInputFieldTarget.type = "password";
      }
    }
  }

  toggleConfirmPass() {
    if (this.hasConfirmPassInputFieldTarget) {
      this.confirmEyeTarget.classList.toggle("fa-eye-slash");
      if (this.confirmPassInputFieldTarget.type == "password") {
        this.confirmPassInputFieldTarget.type = "text";
      } else {
        this.confirmPassInputFieldTarget.type = "password";
      }
    }
  }
}
