import React, { useState } from "react"
import PropTypes from "prop-types"
import FileAttachment from "./FileAttachment";

const Pdf = require("../../assets/images/svg/PDF.svg");

interface FileFieldProps {
  name: string;
  directUploadUrl: string;
  is_import_file: boolean;
  accept: string;
}

const FileField = ({ name, directUploadUrl, is_import_file = false, accept }: FileFieldProps) => {
  const [fileList, setFileList] = useState();

  const handleFileAdd = (e) => {
    setFileList(e.target.files[0]);
  }

  const handleValueChange = (e) => {
    setFileList(null);

    const inputField = document.querySelector(`input[name='${name}']`)
    inputField.value = null;
  }

  let fileProps = {
    type: "file",
    id: name,
    name: name,
    style: { display: "none" },
    onChange: handleFileAdd,
    "data-direct-upload-url": directUploadUrl
  }

  if(is_import_file) {
    fileProps.accept = accept
  }

  return (
    <>
      {fileList ? (
        <FileAttachment filename={fileList.name} byteSize={fileList.size} handleValueChange={handleValueChange} is_import_file={is_import_file} />
      ) : (
        <label htmlFor={name} className="w-100 file-field d-flex align-items-center justify-content-center letter-spacing font-size-sm">UPLOAD FILE</label>
      )}
      <input {...fileProps} />
    </>
  );
}


FileField.propTypes = {
  name: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  is_import_file: PropTypes.bool,
}

FileField.defaultProps = {
  is_import_file: false,
  accept: ""
};

export default FileField;
