import React, { useState } from "react"
import PropTypes from "prop-types";

interface FileFieldProps {
  name: string;
  directUploadUrl: string;
  classStyle: string;
  index: number;
}

const FileField = ({ name, directUploadUrl, classStyle, index }: FileFieldProps) => {
  const [file, setFile] = useState();

  const handleFileAdd = (e) => {
    setFile(e.target.files[0]);
  }

  const handleRemoveFile = (e) => {
    setFile(null);

    const inputField = document.querySelector(`input[name='${name}']`)
    inputField.value = null;

    const parentElement = e.target.closest(".document-row");
    parentElement.remove();
  }

  return (
    <>
      {file ? (
        <div key={index} className="document-wrapper px-2 py-1 font-size-sm mt-2">
          {file.name}
          <span className="ml-5 text-charter-oxford-blue font-size-sm" onClick={(e) => handleRemoveFile(e)}>✕</span>
        </div>
      ) : (
        <label htmlFor={name} className={classStyle}>UPLOAD FILE</label>
      )}
      <input type="file" id={name} name={name} style={{ display: "none" }} onChange={handleFileAdd} data-direct-upload-url={directUploadUrl} />
    </>
  );
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  classStyle: PropTypes.string,
  index: PropTypes.number,
}

FileField.defaultProps = {
  classStyle: "w-100 file-field d-flex align-items-center justify-content-center",
  index: 0
}

export default FileField;
