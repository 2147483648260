import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CustomSelect from "./CustomSelect";
import { DATE_OPTIONS } from './constant';

interface CustomFilterDatePickerProps {
  name: string;
  id_name: string;
  placeholder: string;
  value: string;
}


const CustomFilterDatePicker = ({
  name,
  id_name,
  placeholder,
  value
}:CustomFilterDatePickerProps) => {
  useEffect(() => {
    showHideDateFields(value);
  }, [])
  
  const handleValueChange = (filter) => {
    showHideDateFields(filter.value);
  };

  const showHideDateFields = (value) => {
    let custom_date_creation = document.getElementById(id_name);
    if(value === "custom_range") {
      custom_date_creation.style.display = 'block';
    } else {
      custom_date_creation.style.display = 'none';
    }
  }

  return (
    <div className="form-group">

      <div className="d-flex align-items-center">
        <CustomSelect
          name={name}
          options={DATE_OPTIONS}
          value={value}
          placeholder={placeholder}
          isMulti={false}
          isWhiteBg={false}
          handleValueChange={handleValueChange}
        />
      </div>
    </div>
  );
}

CustomFilterDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  id_name: PropTypes.string.isRequired,
};

export default CustomFilterDatePicker;
