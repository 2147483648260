import React, { useState } from "react"
import PropTypes from "prop-types"

const Bin = require("../../../assets/images/bin.svg");

interface HiddenFieldProps {
  name: string;
  fieldId: string;
}

const HiddenField = ({ name, fieldId }: HiddenFieldProps) => {
  const [deleteField, setDeleteField] = useState(false);

  const handleRemoveField = (e) => {
    setDeleteField(true);
    const field = document.getElementById(fieldId);
    field.style.display = 'none';
  };

  return (
    <>
      <input type="hidden" name={name} value={deleteField} />
      <div><img onClick={handleRemoveField} id="bin-aircraft" src={Bin} /></div>
    </>
  );
}

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
}

export default HiddenField;
