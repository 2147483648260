import React, { useState } from "react"
import PropTypes from "prop-types"

interface FileLabelProps {
  name: string;
  directUploadUrl: string;
}

const FileLabel = ({ name, directUploadUrl }: FileLabelProps) => {
  const [fileList, setFileList] = useState([]);

  const handleFileAdd = (e) => {
    const uploaded = [...fileList];

    Object.values(e.target.files).some(file => {
      if (uploaded.findIndex(f => f.name === file?.name) === -1) {
        uploaded.push(file);
      }
    });

    setFileList(uploaded);
  }

  const handleRemoveFile = (index) => {
    const splicedFiles = fileList.splice(index, 1);
    setFileList([...fileList], ...splicedFiles);
  };

  return (
    <div className="document-input-wrapper">
      <input type="file" id={name} name={name} multiple style={{ display: "none" }} onChange={handleFileAdd} data-direct-upload-url={directUploadUrl} />
      { fileList.length !== 0 ? (
        <>
          {fileList.map((file, index) => (
            <div key={index} className="document-wrapper px-2 py-1 font-size-sm mt-2">
              {file.name}
              <span className="ml-5 text-charter-oxford-blue font-size-sm" onClick={() => handleRemoveFile(index)}>✕</span>
            </div>
          ))}
        </>
        ) : (
          <>
            <label htmlFor={name} className="text-uppercase font-size-xs letter-spacing font-weight-500 text-charter-oxford-blue mt-3 px-2 label-wrapper" id="file2">ADD ANOTHER FILE</label>
          </>
        )
      }
    
    </div>
  );
}

FileLabel.propTypes = {
  name: PropTypes.string.isRequired,
}

export default FileLabel;
