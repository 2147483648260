import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user--country-state"
export default class extends Controller {
  static targets = [
    "country",
    "state",
    "city",
    "website",
    "contact_name",
    "email",
    "secondary_email",
    "phone_number",
    "address_line_1",
    "address_line_2",
    "zip_code",
    "identifier",
    "saveBtn",
  ];

  connect() {
    if (this.stateTarget && !this.stateTarget.value) {
      this.stateTarget.classList.add("selected-box");
    }
  }

  enableBtn() {
    this.saveBtnTarget.disabled = false;
  }

  addStates() {
    this.stateTarget.classList.remove("selected-box");
    this.enableBtn();
    this.resetOptions(this.stateTarget);
    var country = this.countryTarget.value;
    var params = new URLSearchParams({ country: country });

    fetch(`/profiles/basic_details/state_options?${params}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(data => {
        for (const [k, v] of Object.entries(data)) {
          this.stateTarget.options[
            this.stateTarget.options.length
          ] = new Option(k, v);
        }
      });
  }

  addCities() {
    this.stateTarget.classList.remove("selected-box");
    this.enableBtn();
  }

  resetOptions(select) {
    var length = select.options.length;
    for (let i = length - 1; i >= 0; i--) {
      select.options[i] = null;
    }
  }
}
