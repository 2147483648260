import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable-form"
export default class extends Controller {
  static targets = ["fields", "submit"];

  connect() {}

  enableBtn() {
    if (this.hasFieldsTarget) {
      const values = [];
      this.fieldsTargets.forEach(item => values.push(item.value));
      const valuesValid = values.every(v => !!v === true);

      if (valuesValid) {
        return this.submitTarget.removeAttribute("disabled");
      }
    }

    this.submitTarget.setAttribute("disabled", true);
  }
}
