import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomSelect from "../CustomSelect";
import FileField from "./FileField";
import FileDelete from './FileDelete';

interface CertificateUploadMobileProps {
  name: string;
  ratingNames: string[];
  directUploadUrl: string;
  index: number;
  blobId: string;
  fileName: string;
  selectedRating: string;
  safetyCertificateId: string;
}

const CertificateUploadMobile = ({
  name,
  ratingNames,
  directUploadUrl,
  index,
  fileName,
  selectedRating,
  safetyCertificateId
}: CertificateUploadMobileProps) => {
  const ratingName = `${name}[${index}][safety_rating_id]`
  const documentName = `${name}[${index}][document]`
  const deleteName = `${name}[${index}][_destroy]`
  const idName = `${name}[${index}][id]`

  return (
    <div key={index}>
      <div className="d-lg-none">
        <div className="document-row align-items-center mt-2">
          <div className="py-2">
            <div className="text-charter-oxford-blue-60 mb-1 font-size-xs">
              Type
            </div>
            <CustomSelect
              name={ratingName}
              options={ratingNames}
              value={selectedRating}
              placeholder="Select rating"
              isMulti={false}
              isWhiteBg={false} />
          </div>
          <div>
            <div className="text-charter-oxford-blue-60 mb-1 font-size-xs">
              Certificate
            </div>
            {safetyCertificateId ? (
              <div key={index} className="document-wrapper px-2 py-1 font-size-sm mt-2 w-100 d-flex justify-content-between">
                <input type="hidden" name={idName} value={safetyCertificateId} />
                {fileName}
                <FileDelete name={deleteName} blobId={true} />
              </div>
            ) : (
              <FileField
                name={documentName}
                directUploadUrl={directUploadUrl}
                classStyle="text-uppercase font-size-xs letter-spacing font-weight-500 text-charter-oxford-blue py-2 label-wrapper"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

CertificateUploadMobile.propTypes = {
  name: PropTypes.string.isRequired,
  ratingNames: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  selectedRating: PropTypes.string,
  safetyCertificateId: PropTypes.string,
}

export default CertificateUploadMobile;
