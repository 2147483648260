import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shipment--create"
export default class extends Controller {
  static targets = ["element"];

  connect() {
    ReactRailsUJS.mountComponents();
  }

  elementTargetConnected() {
    ReactRailsUJS.mountComponents();
  }
}
