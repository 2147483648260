import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter, components } from 'react-select';

import { CHARTER_ANTIQUE_WHITE_40, CHARTER_WHITE, CHARTER_BLACK, CHARTER_BARN_RED, CHARTER_OXFORD_BLUE_20 } from '../constant';

interface RegionSelectProps {
  name: string;
  options: string[];
  value: string;
  placeholder: string;
}

const customStylesWhite = {
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: CHARTER_OXFORD_BLUE_20
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? CHARTER_ANTIQUE_WHITE_40 : CHARTER_WHITE,
    color: CHARTER_BLACK
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: CHARTER_WHITE,
    fontSize: "1rem",
    padding: "0.25rem",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: CHARTER_BARN_RED
  }),
}

const { Option, Control } = components;

const RegionOption = props => (
  <Option {...props}>
    <div>
      {props.data.label}
    </div>
    <div className="font-size-xs text-charter-oxford-blue-60">
      {props.data.state_names}
    </div>
  </Option>
);

const RegionControl = ({ children, ...props }) => (
  <Control {...props}>
    {children}
  </Control>
);

const RegionSelect = ({ name, options, value, placeholder } : RegionSelectProps) => {

  const defaultValue = options.find(option => option?.value === value)

  const handleSelectChange = () => {
    const savebtn = document.getElementById("save-btn-onboarding")
    if(savebtn){
      savebtn.removeAttribute('disabled');
    }
  }

  return (
    <>
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        defaultValue={defaultValue}
        options={options}
        components={{ Option: RegionOption, Control: RegionControl }}
        isMulti={false}
        styles={customStylesWhite}
        placeholder={placeholder}
        onChange={handleSelectChange}
        name={name} />
    </>
  );
}

RegionSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default RegionSelect;
