import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shipment--increment-decrement"
export default class extends Controller {
  static targets = ["inputField", "incrementBtn", "decrementBtn"];

  connect() {}

  increment() {
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.value =
        parseInt(this.inputFieldTarget.value || 0) + 1;
    }
  }

  decrement() {
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.value =
        parseInt(this.inputFieldTarget.value || 0) - 1;

      if (this.inputFieldTarget.value < 0) {
        this.inputFieldTarget.value = 0;
      }
    }
  }
}
