import React from "react"
import PropTypes from "prop-types"
import Select, { createFilter, components } from 'react-select';

import {
  CHARTER_ANTIQUE_WHITE_40,
  CHARTER_WHITE,
  CHARTER_BLACK,
  CHARTER_BARN_RED,
  ALICE_BLUE_20,
  CHARTER_OXFORD_BLUE_20
} from './constant';

interface FilterDropdownProps {
  name: string;
  options: string[];
  values: string[];
  placeholder: string;
}

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: CHARTER_OXFORD_BLUE_20,
    margin: "auto"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? CHARTER_ANTIQUE_WHITE_40 : CHARTER_WHITE,
    color: CHARTER_BLACK,
    fontSize: "0.875rem",
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: ALICE_BLUE_20,
    fontSize: "0.875rem",
    padding: "0.25rem",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: CHARTER_BARN_RED
  }),
}

const FilterDropdown = ({ name, options, placeholder, values } : FilterDropdownProps) => {
  let selectOptions = [];

  options.map(option => selectOptions.push({label: option[0], value: option[1] }));

  const defaultValues = selectOptions.filter((option) => values.includes(option.value))

  const { Option, ValueContainer } = components;

  const DropdownOption = props => {
    return (
      <Option {...props}>
        <div>
          <input type="checkbox" className="custom-control-input" checked={props.isSelected} readOnly />
          <div className="ml-4 p-0 custom-control-checkbox-label">{props.data.label}</div>
        </div>
      </Option>
    );
  }

  const DropdownValueContainer = ({ children, ...props }) => {
    let [values, input] = children as any;

    if (Array.isArray(values)) {
      const val = (i: number) => values[i].props.children;
      const { length } = values;

      switch (length) {
        case 1:
          values = `${val(0)}`;
          break;
        case 2:
          values = `${val(0)} and ${val(1)}`;
          break;
        case 3:
          values = `${val(0)}, ${val(1)} and ${val(2)}`;
          break;
        default:
          const plural = values.length === 4 ? "" : "s";
          const otherCount = length - 3;
          values = `${val(0)}, ${val(1)}, ${val(
            2
          )} and ${otherCount} other${plural} selected`;
          break;
      }
    }


    return (
      <ValueContainer {...props}>
        <div className="row d-flex flex-nowrap ml-1 text-truncate align-items-center">
          {values}
          {input}
        </div>
      </ValueContainer>
    )
  };

  return (
    <div className="custom-checkbox">
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        options={selectOptions}
        components={{ Option: DropdownOption, ValueContainer: DropdownValueContainer }}
        defaultValue={defaultValues}
        isMulti
        placeholder={placeholder}
        styles={customStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        name={name} />
    </div>
  );
}

FilterDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
}

FilterDropdown.defaultProps = {
  values: []
}

export default FilterDropdown;
