import React, { useState } from "react"
import FileAttachment from "./FileAttachment";
import FileField from "./FileField";

const Pdf = require("../../assets/images/svg/PDF.svg");

interface ExistingFileFieldProps {
  name: string;
  deleteName: string;
  directUploadUrl: string;
  filename: string;
  byte_size: string;
  signed_id: string;
}

const ExistingFileField = ({
  name,
  deleteName,
  directUploadUrl,
  filename,
  byte_size,
  signed_id
}: ExistingFileFieldProps) => {
  const [newdoc, setNewDoc] = useState(false);

  const handleValueChange = () => {
    setNewDoc(true);
  };

  return (
    <>
      { newdoc ? (
        <>
          <input type="hidden" name={deleteName} value={signed_id} />
          <FileField name={name} directUploadUrl={directUploadUrl} />
        </>
      ) : (
        <FileAttachment filename={filename} byteSize={byte_size} handleValueChange={handleValueChange} />
      )}
    </>
  );
}

export default ExistingFileField;
