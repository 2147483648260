import React from "react"
import PropTypes from "prop-types"
import Select, { createFilter } from 'react-select';
import { customStyles, CheckboxValueContainer, DropdownOption } from './utils';
interface MultiSelectDropdownProps {
  name: string;
  options: string[];
  values: string[];
  placeholder: string;
}

const MultiSelectDropdown = ({ name, options, placeholder, values } : MultiSelectDropdownProps) => {
  let selectOptions = [];

  options.map(option => selectOptions.push({label: option[0], value: option[1] }));

  const defaultValues = selectOptions.filter((option) => values.includes(option.value))

  return (
    <>
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        options={selectOptions}
        components={{ Option: DropdownOption, ValueContainer: CheckboxValueContainer }}
        defaultValue={defaultValues}
        isMulti
        placeholder={placeholder}
        styles={customStyles}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        name={name} />
    </>
  );
}

MultiSelectDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  values: PropTypes.array,
  placeholder: PropTypes.string.isRequired,
}

MultiSelectDropdown.defaultProps = {
  values: []
}

export default MultiSelectDropdown;
