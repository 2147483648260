import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const magnifier = require("../../assets/images/svg/magnifier.svg");
const close = require("../../assets/images/svg/close.svg");
var timer = null;

interface SearchProps {
  filter: string;
  defaultQuery: string;
  customClassName: string;
  searchBtnClassName?: string;
  name?: string;
}

const Search = ({
  filter,
  defaultQuery,
  customClassName,
  searchBtnClassName = "",
  name="query"
}: SearchProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const buttonRef = useRef(null);

  const submitForm = (query) => {
    if (timer) clearTimeout(timer);

    setQuery(query);
    timer = setTimeout(() => {
      if (buttonRef) {
        buttonRef.current.click();
      }
    }, 1000);
  }

  const handleValueChange = (e) => {
    submitForm(e.target.value);
  };

  const clearQuery = () => {
    submitForm("");
  }

  return (
    <>
      {filter && <input type="hidden" name="filter" value={filter} />}
      <input
        type="text"
        name={name}
        value={query}
        id="search"
        placeholder="Search"
        className={`p-2 ${customClassName}`}
        onChange={handleValueChange}
      />
      {query ? (
        <img
          src={close}
          id="magnifier"
          className={`${searchBtnClassName}`}
          onClick={clearQuery}
        />
      ) : (
        <img
          src={magnifier}
          id="magnifier"
          className={`${searchBtnClassName}`}
        />
      )}
      <input type="submit" name="commit" className="hidden" ref={buttonRef} />
    </>
  );
};

Search.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

Search.defaultProps = {
  filter: null,
  defaultQuery: "",
  customClassName: "",
};

export default Search;
