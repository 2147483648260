import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types";

import Datetime from "react-datetime";
import * as moment from "moment";

import { DOT_FORMATTED_DATE } from "../constant";

interface DateTimePicker {
  name: string;
  placeholder: string;
  dateFormat: object,
  dataProps: object,
  timeFormat: boolean,
  isShipment: boolean,
  value: string,
  edit: boolean,
  handleChange: object,
  index: number,
  id: string,
  isRequired: string
}

const DateTimePicker = ({
  name,
  placeholder,
  dateFormat,
  dataProps,
  timeFormat,
  isShipment,
  value,
  edit,
  handleChange,
  index,
  id,
  isRequired
}: DateTimePicker) => {
  const [dateValue, setDateValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState("");
  const dateField = useRef(null);

  useEffect(() => {
    if(dateFormat === DOT_FORMATTED_DATE && value) {
      var date = value.split("/");
      convertLocalToUTCDate(new Date(date[2], date[0] - 1, date[1]));
    }
  }, []);


  const validation = (selectedDate) => {
    if(edit) {
      return true;
    }

    return selectedDate.isAfter(moment(new Date().getTime()).subtract(1, 'day'));
  };

  const dateFieldName = `customDate${name}`;

  const defaultInputHash = {
    placeholder: placeholder,
    name: dateFieldName,
    className: "w-100 date-time-picker",
    autoComplete: "off",
    id: id
  }

  const inputProps = { ...defaultInputHash, ...dataProps }

  const convertLocalToUTCDate = (date) => {
    const dateVal = moment.utc(moment(date)).format('MM-DD-YYYY HH:mm');
    setDateValue(dateVal);

    if (!isShipment) {
      const customDateField = document.querySelector(`input[name="${dateFieldName}"]`);
      customDateField.value = moment(date).format(dateFormat);
      customDateField.dispatchEvent(new Event('input', { bubbles: true }));
    }

    displayErrorMessage(date);

    if(index !== null || index !== undefined)
      handleChange(dateVal, index);
    return dateVal;
  }

  const displayErrorMessage = (date) => {
    const dateTimeFormat = timeFormat ? `${dateFormat} hh:mm A` : dateFormat;

    console.log(dateTimeFormat);
    console.log('dateTimeFormat...');
    console.log(moment(date, dateTimeFormat, true));
    if(moment(date, dateTimeFormat, true).isValid()) {
      setErrorMessage("");
    } else {
      setErrorMessage(`Entered date doesn't match the date format ${dateTimeFormat}`);
    }

  }

  useEffect(() => {
    if(dateFormat === DOT_FORMATTED_DATE && value) {
      var date = value.split("/");
      convertLocalToUTCDate(new Date(date[2], date[0] - 1, date[1]));
    }
  }, []);

  useEffect(() => {
    if(dateField.current) {
      var formGroup = dateField.current.closest(".form-group");
      var datePickerError = formGroup.querySelector("#datePickerError");

      if(datePickerError) {
        datePickerError.innerText = errorMessage;
      } else {
        const span = document.createElement("span");
        span.setAttribute("id", "datePickerError");
        span.innerText = errorMessage;
        span.classList = "text-danger font-size-sm";
        formGroup.appendChild(span);
      }
    }
  }, [errorMessage]);

  return (
    <div ref={dateField}>
      <Datetime
        id="customDateField"
        input={true}
        inputProps={inputProps}
        initialValue={value}
        initialViewDate={value}
        dateFormat={dateFormat}
        timeFormat={timeFormat && 'HH:mm'}
        isValidDate={validation}
        required={isRequired}
        onChange={(date) => convertLocalToUTCDate(date)}
      />

      <input type="hidden" name={name} value={dateValue} />
    </div>
  );
}

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  dataProps: PropTypes.object,
  timeFormat: PropTypes.bool,
  isShipment: PropTypes.bool,
  value: PropTypes.string,
  edit: PropTypes.bool,
  handleChange: PropTypes.func,
  index: PropTypes.number,
  id: PropTypes.string,
  isRequired: PropTypes.string,
}

DateTimePicker.defaultProps = {
  dateFormat: "MM-DD-YYYY",
  dataProps: {},
  timeFormat: true,
  id: "customDateField",
  isShipment: false,
  value: new Date(),
  edit: false,
  handleChange: () => {},
  index: null,
  id: "customDateField",
  isRequired: "false"
}

export default DateTimePicker;
