import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter, components } from 'react-select';
import { FixedSizeList as List } from "react-window";

import {
  CHARTER_ANTIQUE_WHITE_40,
  CHARTER_WHITE,
  CHARTER_BLACK,
  CHARTER_BARN_RED,
  ALICE_BLUE_20,
  CHARTER_OXFORD_BLUE_20
} from '../constant';

interface VehicleDropdownProps {
  name: string;
  options: string[];
  value: string;
  isMulti: boolean;
  placeholder: string;
  isWhiteBg: boolean;
}

const { Option } = components;

const CustomOption = props => (
  <Option {...props}>
    { props.data.code ? (
      <div>
        {props.data.name}
      </div>
    ) : (
      <div>
        {props.data.label}
      </div>
    ) }
  </Option>
);

const MenuList = function MenuList(props) {
  const height = 50;
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  const smallWindowMaxHeight = 80;
  const smallwindowHeight = 40

  delete props.onMouseMove
  delete props.onMouseOver

  return (
    <List
      height={ options.length === 2 ? smallWindowMaxHeight : maxHeight}
      itemCount={children.length}
      itemSize={ options.length === 2 ? smallwindowHeight : height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
};

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: CHARTER_OXFORD_BLUE_20
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? CHARTER_ANTIQUE_WHITE_40 : CHARTER_WHITE,
    color: CHARTER_BLACK,
    fontSize: "0.875rem"
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: ALICE_BLUE_20,
    fontSize: "0.875rem",
    padding: "0.25rem",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: CHARTER_BARN_RED
  })
}

const VehicleDropdown = ({ name, options, value, isMulti, placeholder, isWhiteBg = false }:VehicleDropdownProps) => {
  const defaultValue = options.find(option => option.value === value)

  return (
    <div className="custom-checkbox">
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        options={options}
        components={{ MenuList, Option: CustomOption, IndicatorSeparator: () => null }}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isSearchable
        name={name}
        styles={ customStyles }
        placeholder={placeholder} />
    </div>
  );
}

VehicleDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.string,
  placeholder: PropTypes.string,
  isWhiteBg: PropTypes.bool,
};

VehicleDropdown.defaultProps = {
  isMulti: false,
  placeholder: ""
};

export default VehicleDropdown;
