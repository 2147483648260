import React, { useState } from "react"
import PropTypes from "prop-types"

import 'react-phone-number-input/style.css'
import {isValidPhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'

interface PhoneNumberProps {
  name: string;
  formValue: string;
  placeholder: string;
  fromProfile: boolean;
  id: any;
  attr: string;
  handleChange: any;
  className: string;
}

const PhoneNumber = ({ name, formValue = "", placeholder = "Enter phone number", fromProfile, id, attr, handleChange, className = "PhoneComponent" }: PhoneNumberProps) => {
  const [value, setValue] = useState("+1"+formValue);
  const [errMsg, setErrMsg] = useState("")

  const handleOnChange = (e) => {
    setValue(e);
    const valid = e ? isValidPhoneNumber(e) : true;
    setErrMsg( valid ? "" : "Please Enter Valid Number");
    const savebtn = document.getElementById("save-btn-onboarding")
    if(savebtn){
      savebtn.removeAttribute('disabled');
    }

    if(fromProfile) {
      handleChange(e, id, attr)
    }
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <span className="font-size-sm pr-2">+1</span>
        <Input
          placeholder={placeholder}
          value={value}
          className={className}
          onChange={handleOnChange}
          name={name}
          country="US"
          // required={fromProfile}
        />
      </div>
      {errMsg !== "" && (
        <span className="text-danger font-size-sm">
          {errMsg}
        </span>
      )}
    </>
  )
}

PhoneNumber.propTypes = {
  // name: PropTypes.string.isRequired,
  formValue: PropTypes.string,
  placeholder: PropTypes.string,
  fromProfile: PropTypes.bool
}

PhoneNumber.defaultProps = {
  fromProfile: false
}

export default PhoneNumber;
