import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fields", "checkbox"];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    if (this.checkboxTarget.checked) {
      this.fieldsTarget.style.display = "block";
    } else {
      this.fieldsTarget.style.display = "none";
    }
  }
}
