import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import CertificateUploadMobile from "./CertificateUploadMobile";

interface SafetyCertificatesMobileProps {
  name: string;
  ratingNames: string[];
  directUploadUrl: string;
  index: number;
}

const SafetyCertificatesMobile = ({ name, ratingNames, directUploadUrl, index }: SafetyCertificatesMobileProps) => {
  const [certificateIndex, setCertificateIndex] = useState(index);
  const [certificates, setCertificates] = useState([]);

  const handleAddInputs = () => {
    setCertificates([...certificates, certificateIndex]);
    setCertificateIndex(certificateIndex + 1);
  }

  return (
    <>
      {certificates.map(certificate => (
        <CertificateUploadMobile
          name={name}
          ratingNames={ratingNames}
          directUploadUrl={directUploadUrl}
          index={certificate}
        />
      ))}
      <div className="d-lg-none">
        <div className="py-3">
          <div
            className="w-100 text-uppercase add-certificate-btn text-center px-4 py-1 font-size-normal letter-spacing text-charter-oxford-blue-20 rounded"
            onClick={handleAddInputs}
          >
            ADD SERCURITY CERTIFICATE
          </div>
        </div>
      </div>
    </>
  );
}

SafetyCertificatesMobile.propTypes = {
  name: PropTypes.string.isRequired,
  ratingNames: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default SafetyCertificatesMobile;
