import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sign-in"
export default class extends Controller {
  static targets = ["usernameField", "passwordField", "signInBtn"];

  connect() {}

  enableBtn() {
    if (
      this.hasUsernameFieldTarget &&
      this.hasPasswordFieldTarget &&
      this.usernameFieldTarget.value &&
      this.passwordFieldTarget.value
    ) {
      this.signInBtnTarget.removeAttribute("disabled");
    } else {
      this.signInBtnTarget.setAttribute("disabled", true);
    }
  }
}
