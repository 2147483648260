import React, { useState } from "react"
import PropTypes from "prop-types"
import CustomSelect from "../../CustomSelect";
import { optionsWithAll } from "../../utils";

interface SafetyTagsProps {
  name: string;
  options: string[];
}

const SafetyTags = ({ name, options }: SafetyTagsProps) => {
  return (
    <div className="form-group">
      <label className="font-weight-normal font-size-xs">
        Safety tags
      </label>
      <div className="d-flex align-items-center">
        <CustomSelect
          name={name}
          options={optionsWithAll(options)}
          value="all"
          placeholder="Select Safety tags"
          isMulti={false}
          isWhiteBg={false}
        />
      </div>
    </div>
  );
}

SafetyTags.propTypes = {
  name: PropTypes.string.isRequired,
}

export default SafetyTags;
