import consumer from "./consumer";

document.addEventListener("turbolinks:load", () => {
  var userId = document.getElementById("userId");

  if (userId === undefined || userId === null) {
    return false;
  }

  consumer.subscriptions.create(
    { channel: "NotificationsChannel", id: userId.dataset["userId"] },
    {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        var navbarNotification = document.querySelector(
          "#navbar-notification .notification_badge"
        );

        if (navbarNotification) {
          navbarNotification.innerHTML = data["message"];
          navbarNotification.classList.remove("hidden");
        }
      },
    }
  );
});
