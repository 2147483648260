import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import DesktopInput from './DesktopInput';

interface TravellerInputProps {
  attr: string;
  genders: Array<string>,
  index: number;
}

const TravellerInput = ({ attr, genders, index }: TravellerInputProps) => {

  const [idindex, setIdIndex] = useState(0)
  const [travellers, setTravellers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(index + 1);
  const travellerState = { id: idindex, first_name: "", last_name: "", contact_email_id: "", contact_number: "", date_of_birth: "", weight: "", dietary_restrictions: "", gender: "" };
  const [traveller, setTraveller] = useState(travellerState)

  const handleAddInputs = () => {
    setTravellers([...travellers, traveller])
    setCurrentIndex(currentIndex)
    setIdIndex(idindex + 1)
    setTraveller({ ...traveller, ...{ id: idindex + 1 } })
  };

  const handleRemoveInputs = (index) => {
    travellers.splice(index, 1);
    const updatedList = travellers
    setTravellers([...updatedList]);
  };

  const handleStateUpdate = (e, id, key) => {
    let tempTravellers = travellers
    tempTravellers[id][key] = e;
    setTravellers([...tempTravellers]);
  }

  return (
    <>
      <div className="d-none d-lg-block">
        {travellers.map((traveller, id) => {
          return (
            <div key={id}>
              <DesktopInput
                attr={`${attr}[${currentIndex + id}]`}
                id={id}
                traveller={traveller}
                genders={genders}
                handleStateUpdate={handleStateUpdate}
                handleRemoveInputs={handleRemoveInputs}
              />
            </div>)
        }
        )}
        <div>
          <div className="col-11 p-3">
            <div
              className="w-100 text-uppercase dotted-btn text-center px-4 py-1 font-size-normal letter-spacing text-charter-oxford-blue-20"
              onClick={handleAddInputs}
            >
              ADD ANOTHER TRAVELER
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

TravellerInput.propTypes = {
  attr: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  genders: PropTypes.array.isRequired
}

export default TravellerInput;
