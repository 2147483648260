import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import DateTimePicker from "../../shipment_request/DateTimePicker";
import InputFieldComponent from "../../common_components/InputFieldComponent";
import NestedCustomSelect from "../../NestedCustomSelect";
import PhoneNumber from "../phoneNumber/PhoneNumber";
import { DropdownIndicator } from "react-select/dist/declarations/src/components/indicators";
const Bin = require("../../../../assets/images/bin.svg");
const Calender = require("../../../../assets/images/calendar.svg");

interface DesktopInputProps {
  attr: string;
  id: number;
  traveller: object;
  genders: Array<string>;
  handleStateUpdate: object;
  handleRemoveInputs: object;
}

const DesktopInput = ({ attr, id, traveller, genders, handleStateUpdate, handleRemoveInputs }: DesktopInputProps) => {
  return (
    <div className="row">
      <div className="row">
        <div className="col-11 p-0">
          <div className="row p-o m-0">
            <div className="col-4">
              <InputFieldComponent
                label={"First Name"}
                attr="first_name"
                id={id}
                value={traveller.first_name}
                handleChange={handleStateUpdate}
                name={`${attr}[first_name]`}
              />
            </div>
            <div className="col-4">
              <InputFieldComponent
                label={"Last Name"}
                attr="last_name"
                id={id}
                value={traveller.last_name}
                handleChange={handleStateUpdate}
                name={`${attr}[last_name]`}
              />
            </div>
            <div className="col-4">
              <InputFieldComponent
                label={"Contact email ID"}
                attr="contact_email_id"
                id={id}
                // isRequired
                value={traveller.contact_email_id}
                handleChange={handleStateUpdate}
                name={`${attr}[contact_email_id]`}
              />
            </div>
          </div>
          <div className="row p-o m-0">
            <div className="col-4">
              <div className="form-group mb-3">
                <span className="font-size-xs text-charter-oxford-blue-60">Contact number</span>
                <PhoneNumber
                  name={`${attr}[contact_number]`}
                  formValue={traveller.contact_number}
                  placeholder={"Contact number"}
                  id={id}
                  attr={"contact_number"}
                  handleChange={handleStateUpdate}
                  fromProfile
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-3 datetime">
                <span className="font-size-xs text-charter-oxford-blue-60">Date of birth</span>
                <span className="traveller-calendar">
                  <img src={Calender} />
                </span>
                <div className="form-control">
                  <DateTimePicker
                    name={`${attr}[date_of_birth_datetime]`}
                    placeholder={"Date of birth"}
                    dataProps={ { required: "true" } }
                    isRequired={"true"}
                    isShipment={true}
                    edit
                    value={traveller.date_of_birth}
                    index={id}
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <InputFieldComponent
                label={"Weight"}
                attr="weight"
                id={id}
                value={traveller.weight}
                isRequired
                handleChange={handleStateUpdate}
                name={`${attr}[weight]`}
              />
            </div>
          </div>
        </div>
        <div className="col-1 p-0 d-flex align-items-center">
          <img onClick={(e) => handleRemoveInputs(id)} id={"bin-aircraft" + id} src={Bin} />
        </div>
      </div>
      <div className="row p-o m-0 w-100">
        <div className="col-8 p-0">
          <InputFieldComponent
            label={"Dietary Restrictions (Optional)"}
            attr="dietary_restrictions"
            id={id}
            value={traveller.dietary_restrictions}
            handleChange={handleStateUpdate}
            name={`${attr}[dietary_restrictions]`}
          />
        </div>
        <div className="col-4">
          <div className="form-group mb-3">
            <span className="font-size-xs text-charter-oxford-blue-60">Gender</span>
            <NestedCustomSelect
              name={`${attr}[gender]`}
              options={genders}
              placeholder="Select"
              isMulti={false}
              isWhiteBg={true}
              value={traveller.gender}
              index={id}
              handleValueChange={handleStateUpdate}
            />
          </div>
        </div>
      </div>
      <div className="divider py-2"></div>
    </div>
  );
}

DesktopInput.propTypes = {
  attr: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  traveller: PropTypes.object.isRequired,
  genders: PropTypes.array.isRequired,
  handleStateUpdate: PropTypes.object.isRequired,
  handleRemoveInputs: PropTypes.object.isRequired
}

export default DesktopInput;
