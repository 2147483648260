import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// Connects to data-controller="profile--notification"
export default class extends Controller {
  static targets = ["form"];

  connect() {}

  submit() {
    if (this.hasFormTarget) Rails.fire(this.formTarget, "submit");
  }
}
