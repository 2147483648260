import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from 'react-select';

interface NestedCustomSelectProps {
  name: string;
  options: string[];
  value: string;
  isMulti: boolean;
  placeholder: string;
  isWhiteBg: boolean;
  handleValueChange: any;
  index: number;
}

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    fontSize: "0.875rem",
    color: "#CCCED4"
  }),
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: "#F6FAFB",
    fontSize: "0.875rem",
    padding: "0rem",
    height: "40px",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#010928"
  })
}

const customStylesWhite = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: "#FFFFFF",
    fontSize: "0.875rem",
    padding: "0rem",
    height: "40px",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#010928",
    marginBottom: "0px",
    height: "40px"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    fontSize: "14px",
    marginTop: "8px",
    height: "40px"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px"
  }),
}

const NestedCustomSelect = ({
  name,
  options,
  value,
  isMulti,
  placeholder,
  isWhiteBg = false,
  handleValueChange,
  index
}:NestedCustomSelectProps) => {
  let selectOptions = [];

  options.map(option => {
    const obj = { label: option[0], value: option[1] }
    selectOptions = [ ...selectOptions, obj ];
  });

  const defaultValue = !isMulti && selectOptions.find(selectOption => selectOption.value === value)

  return (
    <>
      <div className="w-100 mt-2">
        <Select
          filterOption={createFilter({ ignoreAccents: false })}
          options={selectOptions}
          defaultValue={defaultValue}
          isMulti={isMulti}
          isSearchable
          name={name}
          styles={ isWhiteBg ? customStylesWhite : customStyles }
          placeholder={placeholder}
          onChange={(e) => handleValueChange(e, index)}
        />
      </div>
    </>
  );
}

NestedCustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  isWhiteBg: PropTypes.bool,
};

NestedCustomSelect.defaultProps = {
  isMulti: false,
  placeholder: "",
};

export default NestedCustomSelect;
