import React from "react"
import { components } from 'react-select';

import {
  CHARTER_ANTIQUE_WHITE_40,
  CHARTER_WHITE,
  CHARTER_BLACK,
  CHARTER_BARN_RED,
  ALICE_BLUE_20,
  CHARTER_OXFORD_BLUE_20
} from './constant';

export const bytesToSize = bytes => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const optionsWithAll = options => {
  return [["All", "all"]].concat(options);
};

export const CheckboxValueContainer = ({ children, ...props }) => {
  const { ValueContainer } = components;
  let [values, input] = children;

  if (Array.isArray(values)) {
    const options = values.map(val => val.props.children)
    values = options.toString();
  }

  return (
    <ValueContainer {...props}>
      <div className="row d-flex flex-nowrap ml-1 text-truncate align-items-center">
        {values}
        {input}
      </div>
    </ValueContainer>
  )
};

export const DropdownOption = props => {
  const { Option } = components;

  return (
    <Option {...props}>
      <div className="custom-checkbox">
        <input id={props.data.value} type="checkbox" className="custom-control-input" checked={props.isSelected} readOnly />
        <div className="ml-4 p-0 custom-control-checkbox-label">{props.data.label}</div>
      </div>
    </Option>
  );
}


export const CustomOption = props => {
  const { Option } = components;

  return (
    <Option {...props}>
      { props.data.code ? (
        <div>
          <span className="font-weight-bold">{props.data.code}</span> - {props.data.name}
        </div>
      ) : (
        <div>
          {props.data.label}
        </div>
      ) }
    </Option>
  )
};

export const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: CHARTER_OXFORD_BLUE_20,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? CHARTER_ANTIQUE_WHITE_40 : CHARTER_WHITE,
    color: CHARTER_BLACK,
    fontSize: "0.875rem"
  }),
  control: base => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: ALICE_BLUE_20,
    fontSize: "0.875rem",
    padding: "0.25rem",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: CHARTER_BARN_RED
  })
}
