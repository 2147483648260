import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomSelect from "../CustomSelect";
import FileField from "./FileField";
import FileDelete from './FileDelete';

interface CertificateUploadProps {
  name: string;
  ratingNames: string[];
  directUploadUrl: string;
  index: number;
  blobId: string;
  fileName: string;
  selectedRating: string;
  safetyCertificateId: string;
}

const CertificateUpload = ({
  name,
  ratingNames,
  directUploadUrl,
  index,
  fileName,
  selectedRating,
  safetyCertificateId
}: CertificateUploadProps) => {
  const ratingName = `${name}[${index}][safety_rating_id]`
  const documentName = `${name}[${index}][document]`
  const deleteName = `${name}[${index}][_destroy]`
  const idName = `${name}[${index}][id]`

  return (
    <>
      <div className="d-none d-lg-block">
        <div className=" row document-row d-flex align-items-center mt-2">
          <div className="col-6">
            <div className="text-charter-oxford-blue-60 mb-1 font-size-xs">
              Type
            </div>
            <CustomSelect
              name={ratingName}
              options={ratingNames}
              value={selectedRating}
              placeholder="Select rating"
              isMulti={false}
              isWhiteBg={true} />
          </div>
          <div className="col-6">
            <div className="text-charter-oxford-blue-60 mb-1 font-size-xs">
              Certificate
            </div>
            {safetyCertificateId ? (
              <div key={index} className="document-wrapper px-2 py-1 font-size-sm mt-2">
                <input type="hidden" name={idName} value={safetyCertificateId} />
                {fileName}
                <FileDelete name={deleteName} blobId={true} />
              </div>
            ) : (
              <FileField
                name={documentName}
                directUploadUrl={directUploadUrl}
                classStyle="text-uppercase font-size-xs letter-spacing font-weight-500 text-charter-oxford-blue mt-3 px-2 label-wrapper"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

CertificateUpload.propTypes = {
  name: PropTypes.string.isRequired,
  ratingNames: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  selectedRating: PropTypes.string,
  safetyCertificateId: PropTypes.string,
}

export default CertificateUpload;
