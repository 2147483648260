import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

import CustomSelect from "../CustomSelect";

interface ShipmentRequestFieldProps {
  options: string[];
}

const ShipmentRequestField = ({ options }: ShipmentRequestFieldProps) => {
  const [transplantCenter, setTransplantCenter] = useState("Select transplant center");
  const [amount, setAmount] = useState();
  const amountElement = useRef();

  const handleValueChange = (e) => {
    const value = new URLSearchParams({
      shipment_request_id: e.value
    })

    fetch(`/invoice/shipment_requests?${value}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        setTransplantCenter(response.name);
        setAmount(response.amount);
      });
  };

  useEffect(() => {
    if (amountElement.current) {
      amountElement.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [amount])

  return (
    <>
      <div className="form-group">
        <label className="font-weight-normal font-size-xs">
          UNOS ID
        </label>
        <div className="d-flex align-items-center">
          <CustomSelect
            name="invoice[shipment_request_id]"
            options={options}
            value=""
            placeholder="Select completed transport request"
            isMulti={false}
            isWhiteBg={false}
            handleValueChange={handleValueChange} />
        </div>
      </div>

      <div className="form-group">
        <label className="font-weight-normal font-size-xs">
          Transplant Center
        </label>
        <div className="d-flex align-items-center">
          <div className="form-control text-charter-oxford-blue-20 disabled">
            {transplantCenter}
          </div>
        </div>
      </div>

      <div className="form-group">
        <label className="font-weight-normal font-size-xs">
          Amount
        </label>
        <div className="d-flex align-items-center">
          <span className="position-absolute ml-2">$</span>
          <input
            id="customAmount"
            name="invoice[amount]"
            type="number"
            step="any"
            className="form-control pl-4"
            placeholder="Enter Invoice Amount"
            value={amount}
            onChange={e => setAmount(e.target.value)}
            ref={amountElement}
            required
            data-disable-form-target="fields"
            data-action="input->disable-form#enableBtn"
          />
      </div>
    </div>
    </>
  );
}

ShipmentRequestField.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ShipmentRequestField;
