import React, { useState } from "react"
import PropTypes from "prop-types"

interface FileDeleteProps {
  name: string;
  blobId: any;
}

const FileDelete = ({ name, blobId } : FileDeleteProps) => {
  const [fileBlobID, setFileBlobID] = useState("");

  const handleValueChange = (e) => {
    setFileBlobID(blobId);

    const parentElement = e.target.closest(".document-row");
    parentElement.style.setProperty("display", "none", "important")
  }

  return (
    <>
      <input type="hidden" name={name} value={fileBlobID} />
      <span
        className="ml-5 text-charter-oxford-blue font-size-sm"
        onClick={handleValueChange}
        >
          ✕
        </span>
    </>
  );
}

FileDelete.propTypes = {
  name: PropTypes.string.isRequired,
}

export default FileDelete;
