const CHARTER_ANTIQUE_WHITE_40 = "#eef5f8";
const CHARTER_WHITE = "#FFFFFF";
const CHARTER_BLACK = "#00000";
const CHARTER_BARN_RED = "#010928";
const ALICE_BLUE_20 = "#F6FAFB";
const CHARTER_OXFORD_BLUE_20 = "#CCCED4";
const DATE_OPTIONS = [
  ["All", "all"],
  ["This Week", "this_week"],
  ["Last Week", "last_week"],
  ["Today", "today"],
  ["Last Month", "last_month"],
  ["This Month", "this_month"],
  ["Custom Range", "custom_range"],
];
const DOT_FORMATTED_DATE = "MM/DD/YYYY";

export {
  CHARTER_ANTIQUE_WHITE_40,
  CHARTER_WHITE,
  CHARTER_BLACK,
  CHARTER_BARN_RED,
  ALICE_BLUE_20,
  CHARTER_OXFORD_BLUE_20,
  DATE_OPTIONS,
  DOT_FORMATTED_DATE,
};
